.contact-container {
  background-color: #ffffff; /* White background */
  color: #2c3e50; /* Steel blue text */
  padding: 20px;
  text-align: center;
  height: 88vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;
  max-height: 75vh;
  overflow-y: auto;
}

.contact-container a {
  color: #2980b9; /* Blue for links */
  margin: 10px;
  text-decoration: none;
}
.contact-container p {
  margin: 10px;
}

.social-links {
  display: flex;
  justify-content: center;
}

.social-links a {
  margin: 15px;
}

@media screen and (max-width: 480px) {
  .contact-container{
    max-height: none;
  }
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

main {
  flex: 1 0 auto; /* This will make sure main content expands to fill available space */
}

/* Remove the scrollbar arrows */
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none; /* Ensure arrows are hidden */
}

/* Customize the scrollbar width and appearance */
::-webkit-scrollbar {
  width: 6px; /* Thin scrollbar */
  height: 6px; /* Thin horizontal scrollbar */
}

/* Customize the track */
::-webkit-scrollbar-track {
  background: #f0f0f0; /* Track color */
}

/* Customize the thumb */
::-webkit-scrollbar-thumb {
  background: #888; /* Thumb color */
  border-radius: 10px; /* Rounded edges for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker thumb color on hover */
}

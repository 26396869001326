.footer {
  background-color: #ffffff; /* White background */
  color: #2c3e50; /* Steel blue text */
  padding: 20px 0;
  position: relative;
  bottom: 0;
  width: 100%;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for separation */
}

.footer .footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.footer .content h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #34495e; /* Slightly darker steel blue */
}

.footer .content p {
  font-size: 14px;
  margin: 0;
  color: #34495e;
}

.footer .social-links a {
  color: #2980b9; /* Blue for links */
  margin-left: 15px;
  text-decoration: none;
}

.footer .social-links a:hover {
  color: #34495e; /* Slightly darker on hover */
}
@media screen and (max-width: 480px) {
  .footer .footer-container {
    flex-direction: column;
  }
}

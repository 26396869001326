.herosection-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 100px 0;
}

.herosection-container > div {
  width: 35%;
}
.left-section {
  font-size: 2.8rem;
}
.right-section img {
  width: 80%;
}
#heroAnimation {
  color: rgb(157, 70, 238);
}

.left-section .buttons {
  margin-top: 68px;
}
.left-section .herosection-btn {
  background: #1e2167;
  font-size: 1.2rem;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  padding: 10px;
  margin: 10px;
  text-decoration: none;
  margin-top: 50px;
}
.left-section .herosection-btn:hover {
  background: #404281;
}

@media screen and (max-width: 480px) {
  .herosection-container > div {
    width: 90%;
  }
  .herosection-container {
    flex-direction: column-reverse;
    margin-top: 120px;
    margin-bottom: 80px;
    margin-left: 30px;
  }
  .left-section {
    font-size: 1.6rem;
    margin: 10%;
  }
  .left-section .buttons {
    margin-top: 25px;
  }
}

.about-container {
  background-color: #ffffff; /* White background */
  color: #2c3e50; /* Steel blue text */
  padding: 20px;
  text-align: left;
  width: 100%;
  margin-top: 5rem;
  overflow-y: auto;
  max-height: 77vh;
}

.about-container h1,
.about-container h2,
.about-container h3 {
  text-align: center;
  margin: 20px;
  color: #34495e; /* Slightly darker steel blue */
}

.about-container ul {
  list-style-position: inside;
  margin-left: 65px;
}

.about-container p {
  line-height: 1.6;
  margin: 0px auto;
  width: 90%;
}

@media screen and (max-width: 480px) {
  .about-container ul {
    margin-left: 15px;
  }
  .about-container {
    max-height: none;
  }
}

.project-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff; /* White background */
  padding: 20px;
  color: #2c3e50; /* Steel blue text */
  margin-top: 5rem;
  overflow-y: auto;
  max-height: 77vh;
}

.project-header {
  font-size: 2.2em;
  color: #34495e; /* Slightly darker steel blue */
  margin-bottom: 20px;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 20px;
}

.project-card {
  border: 1px solid #e0e0e0; /* Light gray for card borders */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: box-shadow 0.3s ease-in-out;
  background-color: #f9f9f9; /* Soft gray for the card background */
}
.project-card a {
  text-decoration: none;
  color: #2980b9; /* Blue for links */
}

.project-card:active {
  outline: none;
  box-shadow: none;
}
.project-card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.project-card h3 {
  margin-top: 0;
  color: #2c3e50; /* Steel blue */
}

.project-card p {
  color: #34495e; /* Slightly darker steel blue */
}

.project-card img {
  height: 60%;
  width: 100%;
  object-fit: cover;
  margin: auto;
  border-radius: 10px 10px 0 0;
}
@media screen and (max-width: 480px) {
  .project-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  .project-container {
      margin-top: 6rem;
      max-height: none;
  }
}

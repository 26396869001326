/* Navbar.css */
nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 4rem;
  background-color: #ffffff; /* White background */
  color: #2c3e50; /* Steel blue text */
  position: fixed;
  z-index: 999;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for separation */
}

nav ul {
  list-style: none;
  display: flex;
  gap: 2em;
}

nav a {
  color: #2c3e50;
  text-decoration: none;
}

nav li:hover {
  padding: 4px 10px;
  border: 1px solid #2c3e50; /* Steel blue border */
  border-radius: 5px;
}

nav .toggle-navbar {
  display: none;
  font-size: 1.5rem;
  background: transparent;
  border: none;
  color: #2c3e50;
  cursor: pointer;
  transition: color 0.3s ease;
}
nav .toggle-navbar:hover {
  color: #34495e;
}
@media screen and (max-width: 480px) {
  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    z-index: 99;
  }
  nav ul li {
    padding: 5px 0;
  }
  .navbar-ul-active {
    display: flex;
  }
  nav .toggle-navbar {
    font-size: 1.8rem;
    display: block;
    position: relative;
    left: 50%;
    margin-bottom: 20px;
  }
}
